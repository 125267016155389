<template>
  <el-menu
    :collapse="isCollapse"
    :router="true"
    :default-active="chooseMenu"
    class="layout-menu"
    background-color="#003685"
    text-color="#99B1E0"
    active-text-color="#ffffff"
    :unique-opened="true"
  >
    <template v-for="menuItem in getMenu">
      <!--background-color="#FFFFFF" text-color="#595959" active-color="#F6F8FF" active-text-color="#003685"-->
      <el-submenu
        :index="menuItem.path"
        v-if="menuItem.children"
        :key="menuItem.path"
        :class="{ centerShow: isCollapse }"
      >
        <template slot="title">
          <div class="activeFmenu-icon" v-if="chooseFMenu == menuItem.path"></div>
          <!-- <i class="iconfont" :class="menuItem.icon"></i> -->
          <div class="menu-parent">
            <div class="m-p-img" :class="{ noMr: isCollapse, chooseFMenu: chooseFMenu == menuItem.path }">
              <img class="left-icon" :src="menuItem.icon" alt="" />
            </div>
            <div class="menu-title">
              <span slot="title">{{ menuItem.meta.title }}</span>
            </div>
          </div>
        </template>
        <template v-for="menuChild in menuItem.children">
          <el-menu-item
            :key="menuChild.path"
            :route="{ name: menuChild.name }"
            :index="menuChild.path"
            v-if="!menuChild.hidden"
            >{{ menuChild.meta.title }}
          </el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item
        :index="menuItem.path"
        :route="{ name: menuItem.name }"
        v-else-if="!menuItem.hidden"
        :key="menuItem.path"
      >
        <i class="iconfont" :class="menuItem.icon"></i>
        <span slot="title">{{ menuItem.meta.title }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapState } from "vuex";
import databaseMenuIcon from '@/assets/img/database-menu-icon.png'
import attendanceMenuIcon from '@/assets/img/attendance-menu-icon.png'
import insuranceMenuIcon from '@/assets/img/insurance-menu-icon.png'
import incidenceRateMenuIcon from '@/assets/img/incidenceRate-menu-icon.png'
import infectiousDiseasesMenuIcon from '@/assets/img/infectiousDiseases-menu-icon.png'
import boardExpensesMenuIcon from '@/assets/img/boardExpenses-menu-icon.png'
import homeMenuIcon from '@/assets/img/home-menu-icon.png'
import targetMenuIcon from '@/assets/img/target-menu-icon.png'
import operateMenuIcon from '@/assets/img/operate-menu-icon.png'
import superviseMenuIcon from '@/assets/img/supervise-menu-icon.png'
import dataBoardMenuIcon from '@/assets/img/dataBoard-menu-icon.png'
import systemSetMenuIcon from '@/assets/img/systemSet-menu-icon.png'
export default {
  name: "Menu",
  props: ["module", "isCollapse"],
  data(){
    return{
      chooseMenu: '',
      chooseFMenu: '',
      databaseMenuIcon, //资料库菜单icon
      attendanceMenuIcon,//出勤率菜单icon
      insuranceMenuIcon,//保险菜单icon
      incidenceRateMenuIcon,//发病/患病率菜单icon
      infectiousDiseasesMenuIcon,//传染病菜单icon
      boardExpensesMenuIcon,//伙食费菜单icon
      homeMenuIcon,//首页菜单icon
      targetMenuIcon,//目标菜单icon
      operateMenuIcon,//运营菜单icon
      superviseMenuIcon,//督导菜单icon
      dataBoardMenuIcon,//数据看板菜单icon
      systemSetMenuIcon,//系统设置菜单icon
    }
  },
  watch: {
    getMenu: {
      immediate: true,
      handler(val){
        console.log(val)
        if(!val){
          return
        }
        let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
        let subMenuUrl = ""
        let newSubMenuUrl = ''
        let menuUrl = ""
        let subIndex = -1
        let newSubIndex = -1
        let menuIndex = -1
        if(chooseMenuInfoList && chooseMenuInfoList.length > 0){
          if(this.$route && this.$route.matched.length == 3){
            subMenuUrl = chooseMenuInfoList[0].path
            newSubMenuUrl = this.$route.matched[1].path
            menuUrl = this.$route.matched[2].path
            subIndex = val.findIndex((info) => { return info.path == subMenuUrl })
            newSubIndex = val.findIndex((info) => { return info.path == newSubMenuUrl })
            if(newSubIndex != subIndex && !(subMenuUrl == '/workCalendar' && (menuUrl == '/agencyTaskScoreDetail' || menuUrl == '/agencyTaskViewDetail' || menuUrl == '/upcomingTasksHandle' || menuUrl == '/upcomingTasksFeedback' || menuUrl == '/upcomingTasksDetail' || menuUrl == '/upcomingTasksApprove' || menuUrl == '/uploadProgress'))){
              chooseMenuInfoList[0] = { path: this.$route.matched[1].path, name: this.$route.matched[1].name, meta: { title: this.$route.matched[1].meta.title } }
              chooseMenuInfoList = [].concat([chooseMenuInfoList[0]])
              subIndex = newSubIndex
            }
            if(subIndex != -1){
              menuIndex = val[subIndex].children.findIndex((info) => { return info.path == menuUrl })
            }
            if(menuIndex == -1){
              let chooseIndex = chooseMenuInfoList.findIndex((info) => { return info.path == menuUrl })
              if(chooseIndex == -1){
                chooseMenuInfoList.push({ path: this.$route.matched[2].path, name: this.$route.matched[2].name, meta: { title: this.$route.matched[2].meta.title }, query: this.$route.query })
                this.chooseMenu = chooseMenuInfoList[1].path
                this.chooseFMenu = chooseMenuInfoList[0].path
                sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
              } else {
                let list = []
                chooseMenuInfoList.map((item,i) => {
                  if(i <= chooseIndex){
                    list.push(item)
                  }
                })
                chooseMenuInfoList = list
                this.chooseMenu = chooseMenuInfoList[1].path
                this.chooseFMenu = chooseMenuInfoList[0].path
                sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
              }
            } else {
              if(subIndex != -1){
                if(menuUrl == '/foodExpenseCompany' && this.$route.query.operate == '1' || (menuUrl == '/foodExpenseSchool' && this.$route.query.operate == '1')){
                  // chooseMenuInfoList.push({ path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query })
                  this.chooseMenu = chooseMenuInfoList[1].path
                  this.chooseFMenu = chooseMenuInfoList[0].path
                } else {
                  chooseMenuInfoList = [].concat([chooseMenuInfoList[0],{ path: this.$route.matched[2].path, name: this.$route.matched[2].name, meta: { title: this.$route.matched[2].meta.title }, query: this.$route.query }])
                  this.chooseMenu = menuUrl
                  this.chooseFMenu = chooseMenuInfoList[0].path
                }
                sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
              }
            }
          }
        } else {
          chooseMenuInfoList = []
          if(this.$route && this.$route.matched.length == 3){
            subMenuUrl = this.$route.matched[1].path
            menuUrl = this.$route.matched[2].path
            let firstRouteInfo = { path: this.$route.matched[1].path, name: this.$route.matched[1].name, meta: { title: this.$route.matched[1].meta.title } }
            let secondRouteInfo = { path: this.$route.matched[2].path, name: this.$route.matched[2].name, meta: { title: this.$route.matched[2].meta.title }, query: this.$route.query }
            subIndex = val.findIndex((info) => { return info.path == subMenuUrl })
            if(subIndex != -1){
              menuIndex = val[subIndex].children.findIndex((info) => { return info.path == menuUrl })
            }
            if(menuIndex != -1){
              chooseMenuInfoList = [firstRouteInfo]
              if(menuUrl == '/foodExpenseCompany' && this.$route.query.operate == '1' || (menuUrl == '/foodExpenseSchool' && this.$route.query.operate == '1')){
                let info = val[subIndex].children.find((info) => { return info.path == (menuUrl == '/foodExpenseCompany' && this.$route.query.operate == '1' ? '/foodExpenseIndex' : '/foodExpenseCompany' )})
                chooseMenuInfoList.push(JSON.parse(JSON.stringify(info)))
                this.chooseMenu = (menuUrl == '/foodExpenseCompany' && this.$route.query.operate == '1' ? '/foodExpenseIndex' : '/foodExpenseCompany' )
                this.chooseFMenu = chooseMenuInfoList[0].path
                chooseMenuInfoList.push(secondRouteInfo)
              } else {
                chooseMenuInfoList.push(secondRouteInfo)
                this.chooseMenu = menuUrl
                this.chooseFMenu = chooseMenuInfoList[0].path
              }
              console.log(chooseMenuInfoList)
              sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
            }
          }
        }
        let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList)) 
        breadCrumbList.shift()
        console.log(breadCrumbList)
        this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
      },
      deep: true
    },
    '$route': {
      immediate: true,
      handler(val){
        if(this.menuList.length === 0){
          return
        }
        // console.log(val)
        let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
        let subMenuUrl = ""
        let newSubMenuUrl = ''
        let menuUrl = ""
        let subIndex = -1
        let newSubIndex = -1
        let menuIndex = -1
        if(chooseMenuInfoList && chooseMenuInfoList.length > 0){
          if(val && val.matched.length == 3){
            subMenuUrl = chooseMenuInfoList[0].path
            newSubMenuUrl = val.matched[1].path
            menuUrl = val.matched[2].path
            subIndex = this.menuList.findIndex((info) => { return info.path == subMenuUrl })
            newSubIndex = this.menuList.findIndex((info) => { return info.path == newSubMenuUrl })
            if(newSubIndex != subIndex && !(subMenuUrl == '/workCalendar' && (menuUrl == '/agencyTaskScoreDetail' || menuUrl == '/agencyTaskViewDetail' || menuUrl == '/upcomingTasksHandle' || menuUrl == '/upcomingTasksFeedback' || menuUrl == '/upcomingTasksDetail' || menuUrl == '/upcomingTasksApprove' || menuUrl == '/uploadProgress'))){
              chooseMenuInfoList[0] = { path: val.matched[1].path, name: val.matched[1].name, meta: { title: val.matched[1].meta.title } }
              chooseMenuInfoList = [].concat([chooseMenuInfoList[0]])
              subIndex = newSubIndex
            }
            if(subIndex != -1){
              menuIndex = this.menuList[subIndex].children.findIndex((info) => { return info.path == menuUrl })
            }
            // console.log(menuIndex)
            if(menuIndex == -1){
              let chooseIndex = chooseMenuInfoList.findIndex((info) => { return info.path == menuUrl })
              if(chooseIndex == -1){
                chooseMenuInfoList.push({ path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query })
                this.chooseMenu = chooseMenuInfoList[1].path
                this.chooseFMenu = chooseMenuInfoList[0].path
                sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
              } else {
                let list = []
                chooseMenuInfoList.map((item,i) => {
                  if(i <= chooseIndex){
                    list.push(item)
                  }
                })
                chooseMenuInfoList = list
                this.chooseMenu = chooseMenuInfoList[1].path
                this.chooseFMenu = chooseMenuInfoList[0].path
                sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
              }
            } else {
              if(subIndex != -1){
                if(menuUrl == '/foodExpenseCompany' && val.query.operate == '1' || (menuUrl == '/foodExpenseSchool' && val.query.operate == '1')){
                  chooseMenuInfoList.push({ path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query })
                  this.chooseMenu = chooseMenuInfoList[1].path
                  this.chooseFMenu = chooseMenuInfoList[0].path
                } else {
                  // chooseMenuInfoList[1] = { path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query }
                  chooseMenuInfoList = [].concat([chooseMenuInfoList[0],{ path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query }])
                  this.chooseMenu = menuUrl
                  this.chooseFMenu = chooseMenuInfoList[0].path
                }
                sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
              }
            }
          }
        } else {
          chooseMenuInfoList = []
          if(val && val.matched.length == 3){
            subMenuUrl = val.matched[1].path
            menuUrl = val.matched[2].path
            subIndex = this.menuList.findIndex((info) => { return info.path == subMenuUrl })
            if(subIndex != -1){
              menuIndex = this.menuList[subIndex].children.findIndex((info) => { return info.path == menuUrl })
            }
            if(menuIndex != -1){
              chooseMenuInfoList = [{ path: val.matched[1].path, name: val.matched[1].name, meta: { title: val.matched[1].meta.title } }]
              if(menuUrl == '/foodExpenseCompany' && val.query.operate == '1' || (menuUrl == '/foodExpenseSchool' && val.query.operate == '1')){
                let info = this.menuList[subIndex].children.find((info) => { return info.path == (menuUrl == '/foodExpenseCompany' && val.query.operate == '1' ? '/foodExpenseIndex' : '/foodExpenseCompany' ) })
                chooseMenuInfoList.push({...info})
                this.chooseMenu = (menuUrl == '/foodExpenseCompany' && val.query.operate == '1' ? '/foodExpenseIndex' : '/foodExpenseCompany' )
                this.chooseFMenu = chooseMenuInfoList[0].path
                chooseMenuInfoList.push({ path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query })
              } else {
                chooseMenuInfoList.push({ path: val.matched[2].path, name: val.matched[2].name, meta: { title: val.matched[2].meta.title }, query: val.query })
                this.chooseMenu = menuUrl
                this.chooseFMenu = chooseMenuInfoList[0].path
              }
              console.log(chooseMenuInfoList)
              sessionStorage.setItem("chooseMenuInfoList",JSON.stringify(chooseMenuInfoList))
            }
          }
        }
        let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList))
        breadCrumbList.shift()
        // console.log(breadCrumbList)
        this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
    getMenu() {
      // console.log(this.$router.options.routes);
      // console.log(this.module);
      console.log(this.menuList, "是左侧菜单左侧菜单菜单");
      // return this.$router.options.routes[1].children;
      this.menuList.map((item) => {
        switch(item.path){
          case '/systemSet': //系统管理
            item.icon = this.systemSetMenuIcon
            break;
          case '/database': //资料库
            item.icon = this.databaseMenuIcon
            break;
          case '/attendance': //出勤
            item.icon = this.attendanceMenuIcon
            break;
          case '/insurance': //保险
            item.icon = this.insuranceMenuIcon
            break;
          case '/morbidity': //发病/患病率
            item.icon = this.incidenceRateMenuIcon
            break;
          case '/infectiousDisease': //疾病防控
            item.icon = this.infectiousDiseasesMenuIcon
            break;
          case '/foodExpense': //伙食费
            item.icon = this.boardExpensesMenuIcon
            break;
          case '/workCalendar': //首页
            item.icon = this.homeMenuIcon
            break;
          case '/targetManage': //目标
            item.icon = this.targetMenuIcon
            break;
          case '/opratingManage': //运营
            item.icon = this.operateMenuIcon
            break;
          case '/supervise': //督导
            item.icon = this.superviseMenuIcon
            break;
          case '/dataBoard': //数据看板
            item.icon = this.dataBoardMenuIcon
            break;
          default:
            break;
        }
      })
      return this.menuList
      // this.menuList.find(item => {
      //   return item.path === this.module
      // })
    },
    haveChildMenu() {
      return (menuItem) => {
        return (
          menuItem.children.length &&
          menuItem.children.some((item) => item.type === "0")
        );
      };
    },
  },
};
</script>

<style lang="scss">
.layout-menu {
  border-right: none;

  .el-submenu {
    list-style: none;
    padding-left: 0;
    // padding: 0 0 38px 0;
    padding: 0 0 16px 0;
    &:first-child{
      padding: 0 0 16px 0;
    }
    &.centerShow{
      .el-submenu__title{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0 !important;
      }
    }
  }

  .el-submenu__title {
    height: 48px;
    line-height: 48px;
    padding: 0 0 !important;
    padding-left: 24px !important;
    margin-left: 0 !important;
    font-size: 16px;
    position: relative;
    .activeFmenu-icon{
      position: absolute;
      left: 0;
      width: 3px;
      height: 48px;
      background: #FFFFFF;
      border-radius: 0px 20px 20px 0px;
    }
    .menu-parent {
      display: flex;
      align-items: center;

      .m-p-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 42px;
        height: 42px;
        background: #265497;
        border-radius: 46px;
        margin-right: 16px;
        &.noMr{
          margin-right: 0;
        }
        &.chooseFMenu{
          background: #E7C69C;
          box-shadow: 0px 9px 9px rgba(255, 125, 59, 0.2);
        }
        .left-icon {
          width: 19px;
          height: 19px;
        }
      }

      .menu-title {
      }
    }
  }

  //主菜单悬浮样式
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0 !important;
    color: #409eff !important;
    background: none !important;
  }

  //子菜单悬浮样式
  .el-menu-item:hover {
    outline: 0 !important;
    color: #409eff !important;
    background: none !important;
  }

  .el-submenu .el-menu-item {
    padding-left: 92px !important;
    min-width: 200px;
    font-size: 15px;
    // 2022/1/17 l
    // margin-top: 38px;
    &:first-child{
      // margin-top: 16px;
    }
    height: 46px;
    line-height: 46px;
    // height: 18px;
    // line-height: 18px;
    &:last-child{
      height: 46px;
      line-height: 46px;
    }
  }

  .el-submenu__icon-arrow {
    margin-top: -5px;
  }
}

.el-menu--collapse {
  width: 120px;
}

.layout-menu.el-menu {
  background: none;
}
.layout-menu:not(.el-menu--collapse) {
  // 2022/1/17 l
  // width: 240px;
  height: 100%;
}
</style>
