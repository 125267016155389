<template>
  <el-row
    type="flex"
    class="header"
    justify="space-between"
  >
    <div class="header-collapse">
      <i
        @click="handleCollapse"
        style="font-size: 1.30208vw;margin:0 1.04167vw;"
        :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
      ></i>
      <!-- <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="" /> -->
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          v-if="breadcrumbShowList.length > 0"
        >
          <el-breadcrumb-item
            v-for="item in breadcrumbShowList"
            :key="item.path"
            :to="{ path: item.path, query: item.query || {} }"
          >{{ item.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <el-row
      class="header-right"
      type="flex"
    >
      <el-row
        type="flex"
        class="header-nav"
      >
        <!-- content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。" -->
        <el-popover
          placement="bottom-end"
          popper-class="newsPopover"
          width="360"
          height="23.38542vw"
          trigger="click"
          v-model="visible"
        >
          <i
            class="el-icon-bell"
            slot="reference"
          >
            <span
              class="isRead"
              v-if="countNotRead"
            ></span>
          </i>
          <div class="newsTitle">
            <div></div>
            <div>消息</div>
            <el-button
              type="text"
              @click="readAll"
            >全部已读</el-button>
          </div>
          <ul class="newList">
            <li
              @click="goDetail(item)"
              v-for="item in newsList"
              :key="item.id"
            >
              <div
                :class="item.remindMessageType.value == 1 ? 'newsIcon bg1' : item.remindMessageType.value == 2 ? 'newsIcon bg2' : item.remindMessageType.value == 3 ? 'newsIcon bg4' : item.remindMessageType.value == 4 ? 'newsIcon bg3' :item.remindMessageType.value == 7 ? 'newsIcon bg5': 'newsIcon'"
              >
                <img
                  v-if="item.remindMessageType.value == 1"
                  src="@/assets/img/new-type-1.png"
                  alt=""
                />
                <img
                  v-if="item.remindMessageType.value == 2"
                  src="@/assets/img/new-type-2.png"
                  alt=""
                />
                <img
                  v-if="item.remindMessageType.value == 3"
                  src="@/assets/img/new-type-4.png"
                  alt=""
                />
                <img
                  v-if="item.remindMessageType.value == 4"
                  src="@/assets/img/new-type-3.png"
                  alt=""
                />
                <img
                  v-if="item.remindMessageType.value == 7"
                  src="@/assets/img/new-type-5.png"
                  alt=""
                />
                <span
                  v-if="item.isRead.value == 0"
                  class="isRead"
                ></span>
              </div>
              <div class="newsitem">
                <div class="newsTitle">{{ item.message }}</div>
                <!-- <div class="newsHint" v-if="item == 3">任务名称任务名称任务名称任务名称任务名称任务名称</div> -->
                <div class="newsTime">{{ item.createTime }}</div>
              </div>
            </li>
            <div
              class="historyNews"
              v-if="newsList.length > 0"
              @click="goHisrotyDetail"
            >历史消息</div>
            <div
              class="noData"
              v-if="newsList.length == 0"
            >暂无数据</div>
          </ul>
        </el-popover>
      </el-row>
      <el-row
        type="flex"
        class="header-nav"
      >
        <template v-for="item in businessList">
          <div
            :key="item.id"
            v-if="
              menuList.findIndex((menu) => menu.path === item.busiUrl) !== -1 ||
                /http/.test(item.busiUrl)
            "
            :class="module === item.busiUrl ? 'active' : ''"
            @click="handleChangeModule(item)"
          >
            {{ item.busiName }}
          </div>
        </template>
      </el-row>
      <!-- <div class="header-notice-wrap" @click="goNotice">
        <el-badge class="header-notice" :value="$store.state.notice ? $store.state.notice : '' ">
          <img :style="{ width: '1.25vw', height: '1.25vw' }" :src="noiceImg" alt="">
        </el-badge>
      </div> -->
      <!-- <div @click="handleOperateBtn">
        <el-avatar
          size="medium"
          :src="require('../assets/img/avatar.png')"
          class="header-avatar"
        >
        </el-avatar>
      </div> -->
      <!-- <el-dropdown>
        <span class="el-dropdown-link">
          下拉菜单<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>黄金糕</el-dropdown-item>
          <el-dropdown-item>狮子头</el-dropdown-item>
          <el-dropdown-item>螺蛳粉</el-dropdown-item>
          <el-dropdown-item disabled>双皮奶</el-dropdown-item>
          <el-dropdown-item divided>蚵仔煎</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->

      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-avatar
            size="medium"
            :src="require('../assets/img/avatar.png')"
            class="header-avatar"
          >
          </el-avatar>
          <span style="cursor: pointer;line-height: 3.125vw;color:#333;">
            {{ userInfo.userName }}
          </span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item @click.native="handleEdit"
            >修改密码</el-dropdown-item
          > -->
          <el-dropdown-item @click.native="handleChangeLabel">切换岗位
          </el-dropdown-item>
          <el-dropdown-item @click.native="handleLogout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      append-to-body
      width="35%"
      left
    >
      <el-form
        label-width="4.16667vw"
        label-position="right"
        :model="pwdForm"
        :rules="rules"
        ref="pwdForm"
      >
        <el-form-item
          label="原密码"
          prop="pwd"
        >
          <el-input
            type="password"
            placeholder="请输入原登录密码"
            v-model="pwdForm.pwd"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="newPwd"
        >
          <el-input
            type="password"
            placeholder="请输入新的登录密码"
            v-model="pwdForm.newPwd"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          prop="againPwd"
        >
          <el-input
            type="password"
            placeholder="再次输入登录密码"
            v-model="pwdForm.againPwd"
          ></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          @click="conserve"
        >保存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="切换组织部门岗位"
      :visible.sync="dialogLabelVisible"
      append-to-body
      width="60%"
      left
    >
      <el-table
        :data="tableData"
        border
        v-loading="dialogLabelLoading"
        style="width: 100%"
      >
        <el-table-column
          prop="orgName"
          label="组织"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="schoolName"
          label="学校"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deptName"
          label="部门"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="roleName"
          label="岗位名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop=""
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              :disabled="scope.row.userPostLabel == $store.state.userInfo.userPostLabel"
              @click="changeLabel(scope.row)"
            >切换</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelLabel">取 消</el-button>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import { mapState } from "vuex";
import { removeToken } from "@/utils/auth";
import { validNoContinue, validNoSame } from "@/utils/validate";

export default {
  props: ["module"],
  data () {
    var validatePass = (rule, value, callback) => {
      let rules1 = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{8,16}$/g;
      if (value === "") {
        callback(new Error("请输入8-16位登录密码"));
      } else {
        if (!rules1.test(value)) {
          callback(new Error("登录密码为8-16位且必须有数字、字母"));
        } else if (!validNoSame(value)) {
          callback(new Error("登录密码不允许3个以上的重复字符"));
        } else if (!validNoContinue(value)) {
          callback(new Error("登录密码不允许3个以上的连续字符"));
        } else {
          if (this.pwdForm.againPwd !== "") {
            this.$refs.pwdForm.validateField("againPwd");
          }
          callback();
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback();
      } else if (value !== this.pwdForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      noiceImg: require("@/assets/img/header-noice-icon.png"),
      isCollapse: false,
      businessList: [],
      dialogVisible: false,
      pwdForm: {
        pwd: "",
        newPwd: "",
        againPwd: "",
      },

      rules: {
        pwd: [{ required: true, message: "请输入原登录密码", trigger: "blur" }],
        newPwd: [
          { min: 8, message: "密码长度不能小于8", trigger: "blur" },
          {
            required: true,
            message: "请输入8-16位新的登录密码",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        againPwd: [
          {
            required: true,
            message: "请再次输入新的登录密码",
            trigger: "blur",
          },
          // { min: 6, max: 14, message: '长度在 6 到 14 个字符', trigger: 'blur' }
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      newsList: [],
      countNotRead: 0,
      operateBtnStatus: false,
      role: 0,
      visible: false,
      breadcrumbShowList: [],
      dialogLabelVisible: false,
      columns: [
        {
          label: "模板名称",
          slotName: "templateName",
        },
        {
          label: "项目数量",
          slotName: "totalItem",
        },
        {
          label: "总计时长",
          slotName: "totalPredictDay",
        },
        {
          label: "排序",
          slotName: "templateSort",
        },
        {
          label: "状态",
          slotName: "isUse",
        },
        {
          label: "描述",
          slotName: "templateDesc",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      tableData: [],
      dialogLabelLoading: false,
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
      breadcrumbList: (state) => state.common.breadcrumbList,
    }),
  },
  watch: {
    breadcrumbList: {
      immediate: true,
      handler (val) {
        this.breadcrumbShowList = val
      },
      deep: true
    }
  },
  mounted () {
    this.initNews();
  },
  created () {
    // console.log(this.$store.state.userInfo)
  },
  methods: {
    initNews () {
      this.$api
        .getHistoryNews({
          current: 1,
          size: 15,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.countNotRead = res.data.data.countNotRead;
            this.newsList = res.data.data.list.records;

          } else {
            this.newsList = [];
            this.$message.error(res.data.msg);
          }
        });
    },
    // 修改消息状态
    goDetail (row) {
      if (this.$store.state.userInfo.authList.length == 0) {
        this.role = 3
      } else if (this.$store.state.userInfo.authList[0] == 'org:z') {
        // 总部
        this.role = 1
      } else if (this.$store.state.userInfo.authList[0] == 'org:f') {
        // 分公司
        this.role = 2
      }
      console.log(row, '78787')
      if (row.isRead.value == 0) {
        this.$api
          .setHistoryNewsType({
            id: row.id,
            isRead: 1,
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.initNews();
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
      if (row.taskType.value == 1) {
        // 目标
        if (row.remindMessageType.value == 1) {
          // 跳转目标列表
          // 园校目标列表
          let pathUrl = ''
          if (this.role == 1) {
            pathUrl = '/targetListHeadquarters'
          } else if (this.role == 2) {
            pathUrl = '/targetListCompany'
          } else if (this.role == 3) {
            pathUrl = '/targetListParkschool'
          }
          this.$router.push({
            path: pathUrl
          })
          // this.$router.push({
          //   path: "/targetListParkschool",
          // });
        } else if (
          row.remindMessageType.value == 2 ||
          row.remindMessageType.value == 3
        ) {
          // 跳转目标详情
          this.$router.push({
            path: "/targetDetail",
            query: {
              lookId: row.taskDetailId,
              type: 1,
            },
          });
        }
      } else if (row.taskType.value == 2) {
        // 运营
        if (row.remindMessageType.value == 1) {
          // 跳转任务列表
          let pathUrl = ''
          if (this.role == 1) {
            pathUrl = '/upcomingTasksHead'
          } else if (this.role == 2) {
            pathUrl = '/upcomingTasksBranchOffice'
          } else if (this.role == 3) {
            pathUrl = '/upcomingTasksPrincipal'
          }
          this.$router.push({
            path: pathUrl
          })
        } else if (
          row.remindMessageType.value == 2 ||
          row.remindMessageType.value == 3 ||
          row.remindMessageType.value == 6
        ) {
          // 跳转待办任务详情
          this.$router.push({
            path: "/upcomingTasksDetail",
            query: {
              id: row.taskDetailId,
            },
          });
        }
      } else if (row.taskType.value == 3) {
        // 督导
        let pathUrl = ''
        let jumpType = ''
        console.log(this.role)
        if (this.role == 1) {
          pathUrl = '/agencyTask'
          jumpType = 'headquarters'
        } else if (this.role == 2) {
          pathUrl = '/agencyTaskCompany'
          jumpType = 'company'
        } else if (this.role == 3) {
          pathUrl = '/agencyTaskSchool'
          jumpType = 'school'
        }
        // if (row.remindMessageType.value == 1) {
        //   // 跳转任务列表
        //   // this.$router.push({
        //   //   path: "/superviseTask",
        //   // });
        //   this.$router.push({
        //       path:pathUrl
        //   })
        // } else 
        if (
          row.remindMessageType.value == 2 ||
          row.remindMessageType.value == 3 ||
          row.remindMessageType.value == 1
        ) {
          // 跳转待办任务详情
          // 根据结果来判断页面跳转
          this.$api.judgeStatus(row.taskDetailId).then(res => {
            if (res.data.code == 0) {
              // console.log(res.data.data,'p[p[p')
              if (res.data.data == 1) {
                // data == 1 待办
                this.$router.push({
                  path: '/agencyTaskScoreDetail',
                  query: {
                    id: row.taskDetailId,
                    jumpType: jumpType
                  }
                })
              } else if (res.data.data == 0) {
                // data == 0 查看
                this.$router.push({
                  path: '/agencyTaskShareViewDetail',
                  query: {
                    id: row.taskDetailId,
                    jumpType: jumpType
                  }
                })
              }
            }
          })
        } else if (row.remindMessageType.value == 4) {
          // 跳转分享页面
          this.$router.push({
            path: '/lightspotDetail',
            query: {
              id: row.taskDetailId,
              jumpType: jumpType
            }
          })
        } else if (row.remindMessageType.value == 5) {
          // 跳转分享页面
          this.$router.push({
            path: '/agencyTaskShareViewDetail',
            query: {
              id: row.taskDetailId,
              jumpType: jumpType
            }
          })
        }
      } else if (row.taskType.value == 4) {
        if (row.remindMessageType.value == 7) {
          this.$router.push({
            path: '/diseaseReport',
            query: {
              lookId: row.taskDetailId,
              lookSubStatus: "2",
            }
          })
        }
      }
      this.visible = false

    },
    goHisrotyDetail () {
      this.visible = false
      this.$router.push({ path: '/historicalNews' })
    },
    readAll () {
      if (this.newsList.length == 0) {
        return
      }
      this.$api.readAll().then((res) => {
        if (res.data.code == "0") {
          // this.newsList = res.data.data
          this.initNews();
        } else {
          this.newsList = [];
          this.$message.error(res.data.msg);
        }
      });
    },
    goNotice () {
      this.$router.push("/newsCenter");
    },
    handleCollapse () {
      this.isCollapse = !this.isCollapse;
      this.$emit("collapse", this.isCollapse);
    },
    handleChangeModule (item) {
      if (/http/.test(item.busiUrl)) {
        location.href = item.busiUrl;
      } else {
        this.$router.push(item.busiUrl);
      }
    },

    //个人中心操作按钮
    handleOperateBtn () {
      this.operateBtnStatus = true;
    },

    // 退出登陆  个人资料
    handleLogout () {
      this.$confirm("确认退出, 是否继续?", {
        customClass: "loginShade",
        type: "warning",
        closeOnClickModal: false,
      }).then(() => {
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("chooseMenuInfoList")
        localStorage.removeItem('changePost')
        removeToken("x-access-token");
        this.$router.push("/login");
        this.$message.success("退出成功!");
      });
    },
    // 切换岗位
    handleChangeLabel () {
      this.dialogLabelLoading = true;
      this.dialogLabelVisible = true;
      this.$api.getPostByUser().then(res => {
        this.dialogLabelLoading = false;
        if (res.data.code == 0) {
          this.tableData = this.platFn(res.data.data)
        }
      })
    },
    platFn (list) {
      let res = []
      list.map(item => {
        if (item.userPostInfo instanceof Array && item.userPostInfo.length > 0) {
          res = res.concat(...item.userPostInfo)
        }
      })
      return res
    },
    changeLabel (row) {
      this.$api.changePost(row).then(res => {
        if (res.data.code == 0) {
          this.dialogLabelVisible = false;
          this.$router.push('/workCalendarIndex')
          // let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
          // userInfo.schoolList = [
          //   {companyLabel: row.companyLabel?row.companyLabel:'-1',
          //   companyName: row.companyName?row.companyName:'',
          //   schoolLabel: row.schoolLabel?row.schoolLabel:'-1',
          //   schoolName: row.schoolName?row.schoolName:'',}
          // ],
          // userInfo.officeLabelList = row.deptLabel
          // userInfo.postLabelList = row.postLabel
          let userInfo = res.data.data
          sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
          row.userPhone = userInfo.userPhone
          localStorage.setItem('changePost', JSON.stringify(row))
          this.$store.commit("getUserInfo", (userInfo));
          window.location.reload()
        }
      })
    },
    // 关闭岗位弹窗
    cancelLabel () {
      this.dialogLabelVisible = false;
    },
    // 修改密码
    handleEdit () {
      this.dialogVisible = true;
    },
    conserve () {
      this.$refs["pwdForm"].validate((valid) => {
        if (valid) {
          if (this.pwdForm.newPwd !== this.pwdForm.againPwd) {
            this.$message.warning("两次密码输入不一致");
            return;
          }
          this.$api
            .userEdit({
              passwordOld: this.pwdForm.pwd,
              passwordNew: this.pwdForm.newPwd,
            })
            .then((res) => {
              if (res.data.data) {
                this.$message.success("修改成功");
                this.dialogVisible = false;
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }
      });
    },
    cancel () {
      this.dialogVisible = false;
      this.$refs.pwdForm.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.header {
  height: 60px;
  padding-right: 32px;
  border-bottom: 1px solid rgba(167, 165, 185, 0.43);
  background: #ffffff;
  position: relative;
  z-index: 2000;

  .header-collapse {
    user-select: none;
    cursor: pointer;
    // width: 55px;
    // height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #ffffff;
    }

    i {
      color: #8c94a7;
      line-height: 55px;
    }
  }

  .header-right {
    align-items: center;

    /deep/.el-dropdown-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header-notice-wrap {
      width: 48px;
      height: 48px;
      cursor: pointer;
      .header-notice {
        width: 24px;
        height: 24px;
        margin: 12px;
        text-align: center;
        i {
          display: block;
          width: 20px;
          height: 20px;
          margin: 5px auto 0;
          font-size: 20px;
          color: #888;
        }
      }
    }

    .header-nav {
      div {
        padding: 0 20px;
        border-right: 1px solid #e8e8e8;
        font-size: 16px;
        font-weight: 400;
        line-height: 60px;
        color: #444;
        cursor: pointer;

        &:hover,
        &.active {
          color: #003685;
          font-weight: 600;
        }
      }
    }
    .header-avatar {
      margin: 12px;
      cursor: pointer;
    }
  }
}

.el-icon-bell {
  color: #003685;
  font-size: 30px;
  font-weight: 400;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  .isRead {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 10px;
    height: 10px;
    background-color: #c6000b;
    border-radius: 50%;
  }
}
.newsTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div {
    width: 30%;
    font-size: 16px;
    color: #010334;
  }
  button {
    padding: 0;
    color: #8c94a7;
    font-size: 12px;
    &:hover {
      color: #8c94a7;
    }
  }
}
.newList {
  height: 342px;
  margin-top: 10px;
  border-top: 1px solid #f1f1f5;
  // padding: 0;
  // margin: 10px 0 0;
  &::-webkit-scrollbar {
    width: 5px;
    // background-color: #C5C3D8;
  }
  &::-webkit-scrollbar-thumb {
    background: #c5c3d8; //滚动条颜色
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #fff; //背景颜色
  }
  overflow-y: scroll;

  .bg1 {
    background-color: #eaf1fc;
  }
  .bg2 {
    background-color: #ffe8ea;
  }
  .bg3 {
    background-color: #f8eedf;
  }
  .bg4 {
    background-color: #dff7f3;
  }
  .bg5 {
    background-color: #fcf0ea;
  }
  .newsIcon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .isRead {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 10px;
      height: 10px;
      background-color: #c6000b;
      border-radius: 50%;
    }
  }
  .newsTitle {
    color: #3b3c4d;
    font-size: 14px;
    span {
      margin-top: 6px;
    }
  }
  .newsTime {
    font-size: 12px;
    color: #a8a6b9;
    padding-top: 6px;
  }
  li {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid #e6ebf3;
    padding: 12px 0;
    .newsitem {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .newsHint {
      color: #c6000b;
      font-size: 12px;
      padding-top: 6px;
    }
  }
  .historyNews {
    font-size: 13px;
    font-weight: 400;
    color: #a8a8a8;
    text-align: center;
    padding: 12px 0;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.newsPopover {
  padding: 12px 6px 12px 12px;
  z-index: 3001 !important;
}
.loginShade {
  .el-button--primary {
    width: auto;
    height: auto;
  }
}
.noData {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
